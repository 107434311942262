.footer_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 30px;
}

.footer_icon {
    width: 25px;
}

.footer_link {
    opacity: 0.7;
    transition: 0.35s;
}

.footer_link:hover {
    opacity: 1;
}

@media only screen and (max-width: 768px) {
    .footer_wrapper {
        justify-content: center;
    }
}