.background_video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    /* background: radial-gradient(50% 50% at 50% 50%, #151515 54.17%, rgba(32, 32, 32, 0) 100%); */
    /* background: radial-gradient(50% 50% at 50% 50%, #151515 54.17%, rgba(32, 32, 32, 0) 100%) !important; */
}

.background_video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero_wrapper {
    height: calc(100% - 250px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background: radial-gradient(50% 50% at 50% 50%, #151515 54.17%, rgba(32, 32, 32, 0) 100%); */
    /* background: red; */
}

.absolute {
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, #151515 54.17%, rgba(32, 32, 32, 0) 100%);
    width: 100%;
    height: 80%;
    opacity: 0.7;
    z-index: -1;
}

.hero_title,
.countdown_colon,
.countdown_name,
.countdown_box p {
    font-family: 'Teknikaler', sans-serif;
    /* transition: 0.35s; */
}


.hero_title {
    color: var(--white);
    font-size: 64px;
    letter-spacing: 5px;
}

.countdown_colon {
    color: var(--white);
    font-size: 64px;
    margin-bottom: 40px;
}

.countdown_name {
    font-size: 24px;
    color: var(--white);
    opacity: 0.5;
    letter-spacing: 3px;
}

.countdown_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.countdown_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
}

.countdown_box {
    min-width: 180px;
    min-height: 180px;
    color: var(--white);
    backdrop-filter: blur(5px);
    border: 2px solid var(--white);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.countdown_box p {
    font-size: 64px;
    line-height: 0px;
}

.hero_button {
    background: var(--white);
    border: none;
    border-radius: 50px;
    padding: 20px 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--black);
    font-size: 20px;
    font-weight: 400;
    margin-top: 40px;
    transition: 0.35s;
    cursor: pointer;
}

.hero_button:hover {
    opacity: 0.7;
}

@media only screen and (max-width: 1280px) {

    .hero_title,
    .countdown_box p,
    .countdown_colon {
        font-size: 36px;
    }

    .countdown_box {
        min-width: 120px;
        min-height: 120px;
    }

    .countdown_name {
        font-size: 18px;
    }
}

@media only screen and (max-width: 768px) {

    .hero_title,
    .countdown_box p,
    .countdown_colon {
        font-size: 28px;
    }

    .countdown_box {
        min-width: 100px;
        min-height: 100px;
        border-radius: 10px;
    }

    .countdown_name {
        font-size: 12px;
    }

    .countdown_wrapper {
        gap: 10px;
    }

    .countdown_colon {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 640px) {

    .hero_title,
    .countdown_box p,
    .countdown_colon {
        font-size: 24px;
    }

    .countdown_box {
        min-width: 75px;
        min-height: 75px;
    }

    .countdown_wrapper {
        gap: 5px;
    }

    .countdown_name {
        font-size: 10px;
    }

    .hero_button {
        padding: 15px 110px;
        font-size: 16px;
    }
}